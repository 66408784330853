import { type ReactNode, type FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Typography } from '@ntpkunity/controls'
import { GreyCardStyle } from './greyCardStyle'

interface GreyCardProps {
  icon?: ReactNode
  title?: string
  text?: string
}

export const GreyCard: FC<GreyCardProps> = ({ icon, title, text }) => {
  const theme = useTheme()
  return (
        <GreyCardStyle theme={theme} className="grey-card">
            <Box theme={theme} className='icon'>
                {icon}
            </Box>
            <Typography theme={theme} variant='h4' component='h4' className='title' mb={1}>{title}</Typography>
            <Typography theme={theme} variant='body2' component='p' className='text'>{text}</Typography>
        </GreyCardStyle>
  )
}
