import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { AiWrap } from './aiLandingPageStyle'
import { HomePageLayout } from '@layout/index'
import { Box, Button, Container, Typography } from '@ntpkunity/controls'
import { AiBanner, PrincipleSection, EcosystemSection, JourneySection } from '../components'
import { FadeAnimation } from '@shared/components'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'

export const AiLandingPage: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <HomePageLayout>
      <AiWrap theme={theme} className="ai-wrap">
        <Box
          theme={theme}
          className="banner-area"
          m={{ xs: '-24px 24px 24px 24px', md: '-52px 40px 40px 40px' }}
        >
          <AiBanner />
        </Box>
        <Container theme={theme} maxWidth="lg" className="custom-container">
            <PrincipleSection />
            <EcosystemSection />
            <Box
                  theme={theme}
                  p={{ md: 5, sm: 3, xs: 3 }}
                  className="contact-bar"
                  display={'flex'}
                  flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                  alignItems={'center'}
                >
                  <Box theme={theme} className="plan-content" flexGrow={1}>
                    <FadeAnimation>
                      <Typography
                        theme={theme}
                        variant="h4"
                        component="h4"
                        className="text-h4"
                      >
                        Interested in Learning More?
                      </Typography>
                    </FadeAnimation>
                  </Box>
                  <Box
                    theme={theme}
                    className="plan-cta"
                    flexShrink={0}
                    width={{ xs: '100%', md: 'auto' }}
                    mt={{ xs: 2, md: 0 }}
                  >
                    <FadeAnimation>
                      <Button
                        theme={theme}
                        fullWidth
                        secondary
                        text={'Contact Us'}
                        onClick={() => { navigate(APP_ROUTES.CONTACT_US) }}
                        />
                    </FadeAnimation>
                  </Box>
                </Box>
        </Container>
        <JourneySection />
      </AiWrap>
    </HomePageLayout>
  )
}
