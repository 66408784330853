import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid, Typography } from '@ntpkunity/controls'
import { GreyCard } from '@shared/components'
import { PrincipleSectionStyle } from './principleSectionStyle'
import AiFrameworkIcon from '@shared/assets/images/ai-framework-icon.svg'
import AiSearchIcon from '@shared/assets/images/ai-search-icon.svg'
import AiTagIcon from '@shared/assets/images/ai-tag-icon.svg'
import AiLoopIcon from '@shared/assets/images/ai-loop-icon.svg'
import AiBarsIcon from '@shared/assets/images/ai-bars-icon.svg'
import AiUserIcon from '@shared/assets/images/ai-user-icon.svg'
import AiTrustIcon from '@shared/assets/images/ai-trust-icon.svg'

export const PrincipleSection: FC = () => {
  const theme = useTheme()
  return (
    <PrincipleSectionStyle theme={theme} className='principle-section'>
            <Typography theme={theme} variant='h1' component='h1' textAlign={'center'} className='heading' mb={{ md: 6, sm: 4, xs: 4 }}>
                Platform Design Principles
            </Typography>
            <Grid theme={theme} container rowSpacing={2} columnSpacing={2} alignItems={'center'}>
                <Grid theme={theme} item lg={8} md={7} sm={12} xs={12}>
                    <GreyCard
                        icon={<img src={AiFrameworkIcon} />}
                        title='Powered by AI Orchestration Framework'
                        text='To facilitate rapid development by automatically integrating the relevant data, logic, workflows and action components into a modern, AI-first platform.'
                    />
                </Grid>
                <Grid theme={theme} item lg={4} md={5} sm={12} xs={12}>
                    <GreyCard
                        icon={<img src={AiSearchIcon} />}
                        title='Explainability & transparency'
                        text='To foster trust and reliability by making AI models and outputs clear and understandable.'
                    />
                </Grid>
                <Grid theme={theme} item md={6} sm={12} xs={12}>
                    <GreyCard
                        icon={<img src={AiTagIcon} />}
                        title='Deployment-agnostic and model-agnostic'
                        text='To have adaptable, scalable systems that integrate well with existing tools and platforms through API-first, deployment-agnostic and extensible frameworks.'
                    />
                </Grid>
                <Grid theme={theme} item md={6} sm={12} xs={12}>
                    <GreyCard
                        icon={<img src={AiLoopIcon} />}
                        title='Human-in-the-loop'
                        text='To enable collaborative creation with human oversight, ensuring that AI-generated outputs are relevant, refined, and ethically sound.'
                    />
                </Grid>
                <Grid theme={theme} item md={4} sm={12} xs={12}>
                    <GreyCard
                        icon={<img src={AiBarsIcon} />}
                        title='Scalable & sustainable'
                        text='To build systems that are efficient, scalable, and environmentally conscious while using modern software engineering practices.'
                    />
                </Grid>
                <Grid theme={theme} item md={4} sm={12} xs={12}>
                    <GreyCard
                        icon={<img src={AiUserIcon} />}
                        title='Human feedback'
                        text='To inculcate a dynamic system that evolves based on user feedback and iterative model improvements.'
                    />
                </Grid>
                <Grid theme={theme} item md={4} sm={12} xs={12}>
                    <GreyCard
                        icon={<img src={AiTrustIcon} />}
                        title='Trust'
                        text='To promote fairness, accountability, and transparency across all stages of an AI system lifecycle.'
                    />
                </Grid>
            </Grid>
          </PrincipleSectionStyle>
  )
}
